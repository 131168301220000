/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';
// const localRoleId = localStorage.getItem('roleId');

const routesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'dashbaord',
    title: 'Old eRx',
    messageId: 'sidebar.Olddashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/oldeRx',
  },
  {
    id: 'vaccine',
    title: 'Vaccine',
    messageId: 'sidebar.vaccine',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/vaccine',
  },
  {
    id: 'appointment',
    title: 'Appointment',
    messageId: 'sidebar.appointment',
    type: 'single',
    icon: 'receipt_long',
    auth: authRole.appointmentRoles,
    url: '/appointment',
  },
];
export default routesConfig;
