/************
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : To call Sigin for gudmed operators and admin design page
 * Dependicies : To use react-router-dom,@material-ui/core,formik
 ************/
export const authRole = {
  admin: ['admin'],
  user: ['Doctor', 'Premium Doctor'],
  adminRoles: [1],
  dashboardRoles: [1, 2],
  premiumRole: ['Premium Doctor'],
  appointmentRoles: [1],
};

export const userRole = {
  user: [
    {
      roleId: 1,
      roleName: 'Doctor',
    },
    {
      roleId: 2,
      roleName: 'Premium Doctor',
    },
  ],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/dashboard'; // this url will open after login
export const initUrlProfile = '/my-profile'; // this url will open after login
export const MobileRegExp = /^[0-9\b]+$/;
export const digitRegExp = /^[0-9]*\.?[0-9]*$/;
export const allowDouble = /^[0-9\b.]+$/;
export const nameRegExp = /[^A-Za-z\s]/;
export const addressRegExp = /^[A-Za-z\s][0-9]*\.?[0-9]*$/;
export const AvoidSpecialCharacterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¬£]/;
export const AvoidSpecialCharacterMailRegExp = /[`!#$%^&*()+\=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterDrname = /[`!@#$%^&*_+\-=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterAddressRegExp = /[`!@#$%^*+\=\[\]{};:"\\|<>\?~¬£]/;
export const AvoidSearchpatientRegExp = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\?~¬£]/;
export const MailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const AvoidSpecialCharacterhospitalRegExp = /[`!@#$%^*_+\=\[\]{};:"\\|<>\?~¬£]/;
//  export const nameRegExpdot = /[^A-Za-z\s.]/;
export const nameRegExpdot = /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/;
// /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

export const genderMaster = [
  {
    genderCode: 1,
    gender: 'Male',
  },
  {genderCode: 2, gender: 'Female'},
  {genderCode: 3, gender: 'Transgender'},
];

export const tempceltofaren = [
  {cell: 35.1, faren: 95.2},
  {cell: 35.2, faren: 95.4},
  {cell: 35.3, faren: 95.6},
  {cell: 35.4, faren: 95.8},
  {cell: 35.6, faren: 96.0},
  {cell: 35.7, faren: 96.2},
  {cell: 35.8, faren: 96.4},
  {cell: 35.9, faren: 96.6},
  {cell: 36.0, faren: 96.8},
  {cell: 36.1, faren: 97.0},
  {cell: 36.2, faren: 97.2},
  {cell: 36.3, faren: 97.4},
  {cell: 36.4, faren: 97.6},
  {cell: 36.5, faren: 97.7},
  {cell: 36.6, faren: 97.8},
  {cell: 36.7, faren: 98.0},
  {cell: 36.8, faren: 98.2},
  {cell: 36.9, faren: 98.4},
  {cell: 37.0, faren: 98.6},
  {cell: 37.1, faren: 98.8},
  {cell: 37.3, faren: 99.2},
  {cell: 37.4, faren: 99.4},
  {cell: 37.6, faren: 99.6},
  {cell: 37.7, faren: 99.8},
  {cell: 37.8, faren: 100.0},
  {cell: 37.9, faren: 100.2},
  {cell: 38.0, faren: 100.4},
  {cell: 38.1, faren: 100.6},
  {cell: 38.2, faren: 100.8},
  {cell: 38.3, faren: 101.0},
  {cell: 38.4, faren: 101.2},
  {cell: 38.6, faren: 101.4},
  {cell: 38.7, faren: 101.6},
  {cell: 38.8, faren: 101.8},
  {cell: 38.9, faren: 102.0},
  {cell: 39.0, faren: 102.2},
  {cell: 39.1, faren: 102.4},
  {cell: 39.2, faren: 102.6},
  {cell: 39.3, faren: 102.8},
  {cell: 39.4, faren: 103.0},
  {cell: 39.7, faren: 103.4},
  {cell: 39.8, faren: 103.6},
  {cell: 39.9, faren: 103.8},
  {cell: 40.0, faren: 104.0},
  {cell: 40.1, faren: 104.2},
  {cell: 40.2, faren: 104.4},
  {cell: 40.3, faren: 104.6},
  {cell: 40.4, faren: 104.8},
  {cell: 40.6, faren: 105.0},
  {cell: 40.7, faren: 105.2},
  {cell: 40.8, faren: 105.4},
  {cell: 40.9, faren: 105.6},
  {cell: 41.0, faren: 105.8},
  {cell: 41.1, faren: 106.0},
  {cell: 41.2, faren: 106.2},
  {cell: 41.3, faren: 106.4},
  {cell: 41.4, faren: 106.6},
  {cell: 41.6, faren: 106.8},
];

export const status = {
  pending: 0,
  inprogress: 1,
  rejected: 2,
  completed: 3,
  sendreview: 4,
  returnBack: 5,
};
export const version = '1.0.4';

export const all_prescription_Type = [
  {
    Id: -1,
    type: 'All',
    dispId: -1,
  },
  {
    Id: 1,
    type: 'IPD',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'OPD',
    dispId: 2,
  },
];

export const default_prescription_Type = {
  Id: -1,
  type: 'All',
  dispId: -1,
};
